import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../employee list/employee.css";
import Sidebar from "../../components/sidebar";
import axios from "axios";

const UserApproval = () => {
  const [Employee, setEmployee] = useState("");
  const [activePage, setactivePage] = useState(1);
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    const user = await axios
      .get(`https://api.smartdigilocker.com/user/viewall`)
      .then((res) => {
        return res.data;
      });
    var checkuser = await user.filter((data) => {
      return data.role !== "admin" && data.approve !== "approve";
    });
    setEmployee(checkuser);
  };

  const indexOfLastPost = activePage * 5;
  const indexOfFirstPost = indexOfLastPost - 5;
  const currentPosts = Employee.slice(indexOfFirstPost, indexOfLastPost);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Employee.length / 4); i++) {
    pageNumbers.push(i);
  }

  const editbtn = async (e) => {
    // eslint-disable-next-line no-restricted-globals
    var alert = confirm("Are You Confirm User Approval..");
    if (alert === true) {
      var data = {
        approve: "approve",
        email: e.target.name,
      };
      await axios
        .post(`https://api.smartdigilocker.com/user/updatedata`, data)
        .then((res) => {
          return res.data;
        });
      window.location.reload();
    }
  };
  const deletebtn = async (e) => {
    // eslint-disable-next-line no-restricted-globals
    var alert = confirm("Are You Confirm User Reject..");
    if (alert === true) {
      var data = {
        email: e.target.name,
      };
      await axios
        .post(`https://api.smartdigilocker.com/user/delete`, data)
        .then((res) => {
          return res.data;
        });
      window.location.reload();
    }
  };

  return (
    <div className="employee">
      <Sidebar className="employee_Sidebar" />
      <div className="employee_Rightbar userApprovalPage">
        <div className="employee_RightbarTop">
          <Icon icon="carbon:user-profile" className="employee_Icon" />
          <h6 className="employee_Heading">User Approval</h6>
        </div>
        <div className="employee_RightbarBottom">
          <div className="employee_RightbarBottomleft">
            <div className="employee_lockerContainerapproval_table">
              <table
                class="reports_lockerContainer_heading2 table"
                style={{ color: "white" }}
              >
                <thead>
                  <tr>
                    <th scope="col">S. No</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.length !== 0
                    ? currentPosts.map((data, index) => (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>{data.fname}</td>
                          <td>{data.lname}</td>
                          <td>{data.email}</td>
                          <td>
                            {data.approve !== "approve" ? (
                              <button
                                name={data.email}
                                onClick={editbtn}
                                className="userprofile_input_Button m-2"
                              >
                                Approve
                              </button>
                            ) : null}
                            <button
                              className="userprofile_input_Button m-2"
                              name={data.email}
                              onClick={deletebtn}
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
              <div className="row m-5">
                <ul className="pagination">
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${
                        activePage === number ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => setactivePage(number)}
                        className="page-link"
                      >
                        {number}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserApproval;
