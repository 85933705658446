import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { SidebarData } from "./sidebarData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { Link } from 'react-router-dom';
const Sidebar = () => {
  const [role, setrole] = useState(null);
  const navigate = useNavigate();
  const Signout = () => {
    // eslint-disable-next-line no-restricted-globals
    var alertdata = confirm("Are You Sure Logout..");
    if (alertdata === true) {
      sessionStorage.clear();
      navigate("/");
    }
  };
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    var useremail = sessionStorage.getItem("useremail");
    var user = await axios
      .get(`https://api.smartdigilocker.com/user/check`)
      .then((res) => {
        return res.data;
      });

    var checkuser = user.filter((res) => {
      return res.email === useremail;
    });
    if (checkuser[0].role !== undefined) {
      setrole(checkuser[0].role);
    } else {
      setrole("admin");
    }
  };
  console.log(role);
  return (
    <div className="sidebar">
      <ul className="sidebarList">
        <div className="sidebarLogodiv">
          <img src="/assets/logo.png" className="sidebarLogo" alt="" />
          <h2 className="sidebarLogotext">Smart Digi Locker</h2>
        </div>
        <div className="sidebarline">
          {SidebarData.map((val, key) => {
            // eslint-disable-next-line no-unused-expressions

            return role === "admin" ? (
              val.title !== "Super Admin Ctrl" ? (
                <li
                  key={key}
                  className="row"
                  id={window.location.pathname === val.link ? "active" : null}
                  onClick={() => {
                    window.location.pathname = val.link;
                  }}
                >
                  <div id="icon">{val.icon}</div>
                  <div id="title">{val.title}</div>
                </li>
              ) : null
            ) : (
              <li
                key={key}
                className="row"
                id={window.location.pathname === val.link ? "active" : null}
                onClick={() => {
                  window.location.pathname = val.link;
                }}
              >
                <div id="icon">{val.icon}</div>
                <div id="title">{val.title}</div>
              </li>
            );
          })}
        </div>
        <button className="sidebar_Signout" onClick={Signout}>
          {" "}
          Sign out
        </button>
      </ul>
           <a href='/Privacy-Policy' className="footer">Privacy Policy</a>
    </div>
  );
};

export default Sidebar;
