import {Icon} from "@iconify/react";

export const SidebarData = [
    {
        title:"Dashboard",
        icon:<Icon icon="iwwa:dashboard" />,
        link:"/dashboard"
    },
    {
        title:"Reports",
        icon:<Icon icon="iconoir:reports" />,
        link:"/reports"
    },
    {
        title:"Admin Profile",
        icon:<Icon icon="si-glyph:gear-1" />,
        link:"/userprofile"
    },
    {
        title:"Emp Profile",
        icon:<Icon icon="carbon:user-profile" />,
        link:"/empprofile"
    },
    {
        title:"Locker control",
        icon:<Icon icon="bx:bxs-lock"/>,
        link:"/Lockercontrol"
    },
    {
        title:"User Approval",
        icon:<Icon icon="bx:user-check" />,
        link:"/UserApproval"
    },
    {
        title:"Super Admin Ctrl",
        icon:<Icon icon="carbon:user-settings" />,
        link:"/adminctrl"
    },
]