import React, { useState } from "react";
import "./newpass.css";
import { Icon } from "@iconify/react";
import axios from "axios";

const Newpass = () => {
  const [passwordtype, setpasswordtype] = useState(false);
  const newpassword = async () => {
    var password = document.getElementById("password").value;
    var reenterpassword = document.getElementById("reenterpassword").value;
    if (password === reenterpassword) {
      var data = {
        password: password,
        email: sessionStorage.getItem("email"),
      };
      var newpassword = await axios
        .post(`https://api.smartdigilocker.com/user/update`, data)
        .then((res) => {
          return res.data;
        });
      if (newpassword !== null) {
        window.location.replace("/");
      }
    } else {
      alert("Your Password Not Match..");
    }
  };
  const changepassword = () => {
    setpasswordtype(!passwordtype);
  };
  return (
    <div className="forgatpass_page">
      <div className="forgatpass">
        <img src="/assets/logo.png" alt="" className="forgatpass_logo" />
        <h1 className="forgatpass_Header">Welocome to Smart Digi Locker</h1>
        <p className="forgatpass_Content">Enter the New Password</p>
        <div className="forgatpass_Input">
          {passwordtype === false ? (
            <input
              className="forgatpass_Inputfields"
              placeholder="New Password"
              name="password"
              type="password"
              id="password"
            />
          ) : (
            <input
              className="forgatpass_Inputfields"
              placeholder="New Password"
              name="password"
              type="text"
              id="password"
            />
          )}

          <label htmlFor="username" className="forgatpass_label_Icon">
            <Icon icon="codicon:key" />
          </label>
          {passwordtype !== false ? (
            <span
              onClick={changepassword}
              style={{ color: "white", cursor: "pointer" }}
            >
              <Icon icon="ant-design:eye-filled" />
            </span>
          ) : (
            <span
              onClick={changepassword}
              style={{ color: "white", cursor: "pointer" }}
            >
              <Icon icon="ant-design:eye-invisible-filled" />
            </span>
          )}
        </div>
        <div className="forgatpass_Input">
          {passwordtype === false ? (
            <input
              className="forgatpass_Inputfields"
              placeholder="Re-Enter Password"
              name="password2"
              type="password"
              id="reenterpassword"
            />
          ) : (
            <input
              className="forgatpass_Inputfields"
              placeholder="Re-Enter Password"
              name="password2"
              type="text"
              id="reenterpassword"
            />
          )}

          <label htmlFor="username" className="forgatpass_label_Icon">
            <Icon icon="codicon:key" />
          </label>
          {passwordtype !== false ? (
            <span
              onClick={changepassword}
              style={{ color: "white", cursor: "pointer" }}
            >
              <Icon icon="ant-design:eye-filled" />
            </span>
          ) : (
            <span
              onClick={changepassword}
              style={{ color: "white", cursor: "pointer" }}
            >
              <Icon icon="ant-design:eye-invisible-filled" />
            </span>
          )}
        </div>
        <button
          type="submit"
          className="forgatpass_Submit"
          onClick={newpassword}
        >
          Create Password
        </button>
      </div>
    </div>
  );
};

export default Newpass;
