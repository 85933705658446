import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../user profile/userprofile.css";
import Sidebar from "../../components/sidebar";
import axios from "axios";

const Lockercontrol = () => {
  const [Dashboarddata, setDashboarddata] = useState("");
  const [DashboardInfo, setDashboardInfo] = useState([]);
  const [passcode, setpasscode] = useState("");
  const [show, setshow] = useState(false);
  const [passcode1, setpasscode1] = useState("");
  const [show1, setshow1] = useState(false);
  
  const getInfo = async () => {
    var user = await axios
      .get(`https://api.smartdigilocker.com/user/check`)
      .then((res) => {
        return res.data;
      });
    var adminuser = await user.filter((data) => {
      return data.role === "admin";
    });
    setDashboarddata(adminuser);
  };
  const getInfolocker = async () => {
    try {
      const { data } = await axios.get(
        "https://api.smartdigilocker.com/locker/lockerdata"
      );
      const users = await axios.get(
        "https://api.smartdigilocker.com/user/check"
      );
      setDashboardInfo(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInfo();
    getInfolocker();
  }, []);

  // locker
  const [locker, setLocker] = useState("");
  var email = sessionStorage.getItem('useremail')
  console.log(email);
  const unlock = async () => {
    if (passcode1 === null || passcode1 === undefined || passcode1 === "") {
      alert("Please Fill passcode")
    } else {
      var singlelocker = await DashboardInfo.filter((data) => {
        return data._id === locker;
      });
      console.log(singlelocker[0]);
      await axios.post(
        "https://api.smartdigilocker.com/locker/adminunlock",
        {...singlelocker[0],email,passcode:passcode1}
      ).catch((err)=>{
        alert(err.response.data.message)
        });
      var datanew = await axios.post(
        "https://api.smartdigilocker.com/locker/unlockupdate",
        singlelocker[0]
      );
      if (datanew !== null) {
        window.location.reload();
      }
    }
    

  };

  const unlockAll = async () => {
    if (passcode === null || passcode === undefined || passcode === "") {
      alert("Please Fill passcode")
    } else {
      var email = sessionStorage.getItem('useremail')
      console.log(email);
      for (let i = 0; i < DashboardInfo.length; i++) {
        await axios.post(
          "https://api.smartdigilocker.com/locker/adminunlock", 
          {...DashboardInfo[i],email,passcode} 
        ).catch((err)=>{
          alert(err.response.data.message)
          });
        await axios.post(
          "https://api.smartdigilocker.com/locker/unlockupdate",
          DashboardInfo[i]
        );
      }
      window.location.reload();
    }

  };

  const open =async ()=>{
    var email = sessionStorage.getItem('useremail')
    console.log(email);
    setshow(true)
  const data =   await axios.post("https://api.smartdigilocker.com/locker/lockerpasscode",{email});
  console.log(data);
  }

  const open1 = async ()=>{
    var email = sessionStorage.getItem('useremail')
    console.log(email);
    setshow1(true)
    const data =   await axios.post("https://api.smartdigilocker.com/locker/lockerpasscode",{email});
    console.log(data);
  }
  return (
    <>
    <div className="userprofile">
      <Sidebar className="userprofile_Sidebar" />
      <div className="userprofile_Rightbar">
        <div className="userprofile_RightbarTop">
          <Icon icon="si-glyph:gear-1" className="userprofile_Icon" />
          <h4 className="userprofile_Heading">Locker Control</h4>
        </div>
        <div className="userprofile_RightbarBottom">
          <div className="userprofile_RightbarBottomleft">
            <div className="adminCtrl_lockerContainer">
              <div className="adminCtrl_lockerContainertop">
                <h6>Locker control </h6>
              </div>

              <hr />
              <div className="adminctrl_databoard">
                <div className="adminctrl_databoard_top">
                  <label className="adminctrl_label">
                    Open Selected Locker
                  </label>
                  <select
                    onChange={(e) => {
                      const lock = e.target.value;
                      setLocker(lock);
                      setDashboardInfo(
                        DashboardInfo.filter((item) =>
                          item.name.toLowerCase().match(locker.toLowerCase())
                        )
                      );
                    }}
                  >
                    {(() => {
                      if (DashboardInfo.length !== 0) {
                        return (
                          <>
                            <option>Select Locker</option>
                            {DashboardInfo.map((values, key) => {
                              return (
                                <>
                                  <option
                                    className="adminctrl_option"
                                    key={key}
                                    value={values._id}
                                  >
                                    {values.name}
                                  </option>
                                </>
                              );
                            })}
                          </>
                        );
                      }
                    })()}
                  </select>
                  <button
                    className="adminctrl_Button"
                    type="submit"
                    onClick={open1}
                  >
                    Open
                  </button>
                </div>
                <br />
                <div className="adminctrl_databoard_bottom">
                  <label className="adminctrl_label">Open All Locker</label>
                  <button
                    type="button"
                    className="adminctrl_Button"
                    onClick={open}
                  >
                    Open
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {show && <div className="locker-wrapper">
      <div className="locker-container">
      <Icon icon="mdi:warning-outline"  />
        {/* <h4>Alert !</h4> */}
        <p>Enter the OTP sent to your Registered Email</p>
        <input type='text' value={passcode} onChange={(e)=> setpasscode(e.target.value)}/>
        <div className="btn-con">
        <button onClick={()=>setshow(false)}>Cancel</button>
        <button onClick={unlockAll}>Confirm</button>
        </div>
      </div>
    </div>}
    {show1 && <div className="locker-wrapper">
      <div className="locker-container">
      <Icon icon="mdi:warning-outline"  />
        {/* <h4>Alert !</h4> */}
        <p>Enter the OTP sent to your Registered Email</p>
        <input type='text' value={passcode1} onChange={(e)=> setpasscode1(e.target.value)}/>
        <div className="btn-con">
        <button onClick={()=>setshow(false)}>Cancel</button>
        <button onClick={unlock}>Confirm</button>
        </div>
      </div>
    </div>}
    </>
  );
};

export default Lockercontrol;
