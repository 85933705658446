import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "./employee.css";
import Sidebar from "../../components/sidebar";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Employee = () => {
  const [Employee, setEmployee] = useState("");
  const [activePage, setactivePage] = useState(1);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [address, setaddress] = useState("");
  const [password, setpassword] = useState("");
  const [passwordtype, setpasswordtype] = useState(false);
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    const user = await axios
      .get(`https://api.smartdigilocker.com/user/viewall`)
      .then((res) => {
        return res.data;
      });
    var checkuser = await user.filter((data) => {
      return data.role !== "admin" && data.role !== "superadmin";
    });
    setEmployee(checkuser);
  };

  const indexOfLastPost = activePage * 20;
  const indexOfFirstPost = indexOfLastPost - 20;
  const currentPosts = Employee.slice(indexOfFirstPost, indexOfLastPost);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Employee.length / 20); i++) {
    pageNumbers.push(i);
  }
  const adduser = async () => {
    console.log(Employee);

      // var name = document.getElementById("name").value;
      var fname = document.getElementById("fname").value;
      var lname = document.getElementById("lname").value;
      var email = document.getElementById("email").value;
      // var address = document.getElementById("address").value;
      var password = document.getElementById("password").value;
      var cpassword = document.getElementById("cpassword").value;
     if(fname === '' || fname === null || fname === undefined){
      alert('Please Fill All Fields')
     }else if(lname === '' || lname === null || lname === undefined){
      alert('Please Fill All Fields')
     }
     else if(email === '' || email === null || email === undefined){
      alert('Please Fill All Fields')
    }
    else if(password === '' || password === null || password === undefined){
      alert('Please Fill All Fields')
    }
    else if(cpassword === '' || cpassword === null || cpassword === undefined){
      alert('Please Fill All Fields')
    }
   else
      if (password === cpassword) {
        var data = {
          // username: name,
          email: email,
          password: password,
          role: "employee",
          fname: fname,
          lname: lname,
          approve: "approve",
        };
        var adduser = await axios
          .post("https://api.smartdigilocker.com/user/register", data)
          .then((res) => {
            return res.data;
          });

        alert(adduser.message);
        window.location.reload();
      } else {
        alert("Password Not Match..");
      }
   
  };
  const editbtn = async (e) => {
    var checkdata = await Employee.filter((data) => {
      return data._id === e.target.name;
    });
    setemail(checkdata[0].email);
    setname(checkdata[0].username);
    setfirstname(checkdata[0].fname);
    setlastname(checkdata[0].lname);
    setpassword('')
    // setaddress(checkdata[0].address);
  };
  const deletebtn = async (e) => {
    console.log(e.target.name);
    var data = {
      email: e.target.name,
    };
    await axios
      .post(`https://api.smartdigilocker.com/user/delete`, data)
      .then((res) => {
        return res.data;
      });
    window.location.reload();
  };
  const saveuser = async () => {
    var email = document.getElementById("emailedit").value;
    // var name = document.getElementById("nameedit").value;
    var fnameedit = document.getElementById("fnameedit").value;
    var lnameedit = document.getElementById("lnameedit").value;
    var role = document.getElementById("role").value;
    var password = document.getElementById("passwordedit").value;
    // var addressedit = document.getElementById("addressedit").value;

    var data = {
      // username: name,
      email: email,
      fname: fnameedit,
      lname: lnameedit,
      role,
      password
      // address: addressedit,
    };
    console.log(data);
    if(fnameedit === '' || fnameedit === null || fnameedit === undefined){
      alert('Please Fill All Fields')
     }else if(lnameedit === '' || lnameedit === null || lnameedit === undefined){
      alert('Please Fill All Fields')
     }
     else if(email === '' || email === null || email === undefined){
      alert('Please Fill All Fields')
    }
    else if(password === '' || password === null || password === undefined){
      alert('Please Fill All Fields')
    }
    else{
      await axios
      .post(`https://api.smartdigilocker.com/user/updatedata`, data)
      .then((res) => {
        return res.data;
      });
    window.location.reload();
    }

   

  };
  const changepassword = () => {
    setpasswordtype(!passwordtype);
  };
  const searchname =async (e) => {
    console.log(e.target.value);
    await  getuserdata();

    const user = await axios
      .get(`https://api.smartdigilocker.com/user/viewall`)
      .then((res) => {
        return res.data;
      });
    var checkuser = await user.filter((data) => {
      return data.role !== "admin" && data.role !== "superadmin";
    });

    if (e.target.value.length === 0) {
      getuserdata();
    } else {

      var filteruser = [];
      for (var i = 0; i < checkuser.length; i++) {
        console.log('tyhyfy');
        if (
          checkuser[i].email.toLowerCase().match(e.target.value.toLowerCase())
        ) {
          filteruser.push(checkuser[i]);
        }
      }
      setEmployee(filteruser);
    }
  };
  return (
    <div className="employee">
      <Sidebar className="employee_Sidebar" />
      <div className="employee_Rightbar">
        <div className="employee_RightbarTop">
          <Icon icon="carbon:user-profile" className="employee_Icon" />
          <h6 className="employee_Heading">Employee List</h6>
          {/* <form>
                        <div className='employee_search_Input'>
                            <input
                                className='employee_search_Inputfields'
                                placeholder='Search'
                                name='username'
                                type="text"
                            >
                            </input>
                            <label htmlFor='username' className='employee_search_Icon'>
                                <Icon icon="charm:search" />
                            </label>
                        </div>
                    </form> */}
        </div>
        <div className="employee_RightbarBottom">
          <div className="employee_RightbarBottomleft">
            <div className="employee_lockerContainer">
              <div className="employee_lockerContainertop">
                <h6>Employee List</h6>
                <form>
                  <div className="employee_lockerContainer2_search_Input">
                    <input
                      className="employee_lockerContainer2_search_Inputfields"
                      placeholder="Search"
                      name="username"
                      type="text"
                      onChange={searchname}
                      // onFocus={searchname}
            
                    ></input>
                    <label
                      htmlFor="username"
                      className="employee_lockerContainer2_search_Icon"
                    >
                      <Icon icon="charm:search" />
                    </label>
                  </div>
                </form>
              </div>
              <hr />

              {/* {
                        Employee.map((values, key) => {
                            return <>
                                <Row>
                                    <Col>{values.email}</Col>
                                    <Col>{values.username}</Col>
                                </Row>
                            </>
                    })} */}
              <table
                class="reports_lockerContainer_heading2 employeeTable table"
                style={{ color: "white", height: "200px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">S. No</th>
                    {/* <th scope="col">User Name</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.length !== 0
                    ? currentPosts.map((data, index) => (
                        <tr key={index} className="datacell">
                          <th>{index + indexOfFirstPost +1}</th>
                          {/* <td>{data.username}</td>
                          <td>{data.fname}</td>
                          <td>{data.lname}</td> */}
                          <td>{data.email}</td>
                          <td>
                            <div className="actionbtn">
                            <button
                              name={data._id}
                              onClick={editbtn}
                              className="userprofile_input_Button1 "
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              Edit
                            </button>
                            <button
                              className="userprofile_input_Button1"
                              name={data.email}
                              onClick={deletebtn}
                            >
                              Delete
                            </button>
                            </div>
                            
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div
                      class="modal-content"
                      style={{ backgroundColor: "#212529" }}
                    >
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Edit User
                        </h5>
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="userprofile_Form">
                          {/* <label>User Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={name}
                            id="nameedit"
                            className="userprofile_Input"
                          ></input>
                          <br /> */}
                          <label>First Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={firstname}
                            id="fnameedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          <label>Last Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={lastname}
                            id="lnameedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          {/* <label>Address</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={address}
                            id="addressedit"
                            className="userprofile_Input"
                          ></input>
                          <br /> */}
                          <label>Email</label>
                          <br />
                          <input
                            type="type"
                            defaultValue={email}
                            id="emailedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          <label>Password</label>
                          <br />
                          <input
                            type="type"
                            value={password}
                            // defaultValue={password}
                            id="passwordedit"
                            className="userprofile_Input"
                            onChange={(e)=>setpassword(e.target.value)}
                          ></input>
                          <br />
                          <label>Role</label>
                          <br />
                          <select id="role">employee
                            <option value="employee">Employee</option>
                            <option value="admin">Admin</option>
                            <option value="superadmin">Super Admin</option>
                          </select>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          onClick={saveuser}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </table>
              <div className="row m-5">
                <ul className="pagination">
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${
                        activePage === number ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        onClick={() => setactivePage(number)}
                        className="page-link"
                      >
                        {number}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="userprofile_lockerInfo">
            <div className="userprofile_lockerInfotop">
              <h3>Add Employee</h3>
              <img
                className="userprofile_profileImg"
                src="./assets/profile.png"
                alt=""
              />
            </div>
            <div className="userprofile_Form">
              {/* <label>User Name</label>
              <br />
              <input
                type="text"
                id="name"
                className="userprofile_Input"
              ></input>
              <br /> */}
              <label>First Name</label>
              <br />
              <input
                type="text"
                id="fname"
                className="userprofile_Input"
              ></input>
              <br />
              <label>Last Name</label>
              <br />
              <input
                type="text"
                id="lname"
                className="userprofile_Input"
              ></input>
              <br />
              <label>Email</label>
              <br />
              <input
                type="type"
                id="email"
                className="userprofile_Input"
              ></input>
              <br />
              {/* <label>Address</label>
              <br />
              <input
                type="text"
                id="address"
                className="userprofile_Input"
              ></input>
              <br /> */}
              <label>Password</label>
              <br />
              {passwordtype === false ? (
                <input
                  type="password"
                  id="password"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              ) : (
                <input
                  type="text"
                  id="password"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              )}

              {passwordtype !== false ? (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-filled" />
                </span>
              ) : (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-invisible-filled" />
                </span>
              )}
              <br />
              <label>Confirm Password</label>
              <br />
              {passwordtype === false ? (
                <input
                  type="password"
                  id="cpassword"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              ) : (
                <input
                  type="text"
                  id="cpassword"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              )}

              {passwordtype !== false ? (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-filled" />
                </span>
              ) : (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-invisible-filled" />
                </span>
              )}
              <br />
              <button className="userprofile_input_Button" onClick={adduser}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;
