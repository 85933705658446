import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "./userprofile.css";
import Sidebar from "../../components/sidebar";
import axios from "axios";

const Userprofile = () => {
  const [Dashboarddata, setDashboarddata] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [address, setaddress] = useState("");
  const [passwordtype, setpasswordtype] = useState(false);
  const [DashboardInfo, setDashboardInfo] = useState([]);
  const [role, setRole] = useState('');

  const getInfo = async () => {
    var user = await axios
      .get(`https://api.smartdigilocker.com/user/check`)
      .then((res) => {
        return res.data;
      });
    var adminuser = await user.filter((data) => {
      return data.role === "admin" || data.role === "superadmin" ;
    });
    setDashboarddata(adminuser);
  };
  const getInfolocker = async () => {
    try {
      const { data } = await axios.get(
        "https://api.smartdigilocker.com/locker/lockerdata"
      );
      const users = await axios.get(
        "https://api.smartdigilocker.com/user/check"
      );
      setDashboardInfo(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInfo();
    getInfolocker();
  }, []);
  const adduser = async () => {
    if (Dashboarddata.length < 5) {
      // var name = document.getElementById("name").value;
      var fname = document.getElementById("fname").value;
      var lname = document.getElementById("lname").value;
      var email = document.getElementById("email").value;
      var role = document.getElementById("role1").value;
      var password = document.getElementById("password").value;
      var cpassword = document.getElementById("cpassword").value;
      if(fname === '' || fname === null || fname === undefined){
        alert('Please Fill All Fields')
       }else if(lname === '' || lname === null || lname === undefined){
        alert('Please Fill All Fields')
       }
       else if(email === '' || email === null || email === undefined){
        alert('Please Fill All Fields')
      }
      else if(password === '' || password === null || password === undefined){
        alert('Please Fill All Fields')
      }
      else if(cpassword === '' || cpassword === null || cpassword === undefined){
        alert('Please Fill All Fields')
      }
     else
      if (password === cpassword) {
        var data = {
          email: email,
          password: password,
          role: role,
          fname: fname,
          lname: lname,
          approve: "approve",
        };
        var adduser = await axios
          .post("https://api.smartdigilocker.com/user/register", data)
          .then((res) => {
            console.log(res);
            return res.data;
          })
          .catch((e)=>{
            alert(e.response.data.error)
            console.log(e.response.data.error);
          })
        console.log(adduser);
        alert(adduser.message);
        window.location.reload();
      } else {
        alert("Password Not Match..");
      }
    } else {
      alert("Only 5 maximum admin users can be added..");
    }
  };
  const editbtn = async (e) => {
    var checkdata = await Dashboarddata.filter((data) => {
      return data._id === e.target.name;
    });
    console.log(checkdata[0].role);
    setemail(checkdata[0].email);
    setname(checkdata[0].username);
    setfirstname(checkdata[0].fname);
    setlastname(checkdata[0].lname);
    setpassword('')
    setRole(checkdata[0].role)
    // setaddress(checkdata[0].address);
  };
  const saveuser = async () => {
    var email = document.getElementById("emailedit").value;
    // var name = document.getElementById("nameedit").value;
    var fnameedit = document.getElementById("fnameedit").value;
    var lnameedit = document.getElementById("lnameedit").value;
    var role = document.getElementById("role").value;
    var password = document.getElementById("passwordedit").value;
    // var addressedit = document.getElementById("addressedit").value;

    var data = {
      // username: name,
      email: email,
      fname: fnameedit,
      lname: lnameedit,
      role,
      password
      // address: addressedit,
    };
    console.log(data);
    if(fnameedit === '' || fnameedit === null || fnameedit === undefined){
      alert('Please Fill All Fields')
     }else if(lnameedit === '' || lnameedit === null || lnameedit === undefined){
      alert('Please Fill All Fields')
     }
     else if(email === '' || email === null || email === undefined){
      alert('Please Fill All Fields')
    }
    else if(password === '' || password === null || password === undefined){
      alert('Please Fill All Fields')
    }
    else{
      await axios
      .post(`https://api.smartdigilocker.com/user/updatedata`, data)
      .then((res) => {
        return res.data;
      });
    window.location.reload();

    }

  };
  const deletebtn = async (e) => {
    console.log(e.target.name);
    var data = {
      email: e.target.name,
    };
    await axios
      .post(`https://api.smartdigilocker.com/user/delete`, data)
      .then((res) => {
        return res.data;
      });
    window.location.reload();
  };
  const changepassword = () => {
    setpasswordtype(!passwordtype);
  };
  console.log(Dashboarddata);
  // locker
  const [locker, setLocker] = useState("");
  const unlock = async () => {
    console.log(locker);
    var data = {
      user: "",
      name: locker,
      userstatus: "Available",
    };
    var datanew = await axios.post(
      "https://api.smartdigilocker.com/locker/updateuser",
      data
    );
    console.log(datanew);

    window.location.reload();
  };

  const unlockAll = async () => {
    for (let i = 0; i < DashboardInfo.length; i++) {
      var data = {
        user: "",
        name: DashboardInfo[i].name,
        userstatus: "Available",
      };
      var datanew = await axios.post(
        "https://api.smartdigilocker.com/locker/updateuser",
        data
      );
    }
    window.location.reload();
  };

  return (
    <div className="userprofile">
      <Sidebar className="userprofile_Sidebar" />
      <div className="userprofile_Rightbar">
        <div className="userprofile_RightbarTop">
          <Icon icon="si-glyph:gear-1" className="userprofile_Icon" />
          <h1 className="userprofile_Heading">Admin Profile</h1>
        </div>
        <div className="userprofile_RightbarBottom">
          <div className="userprofile_RightbarBottomleft">
            {/* <div className="adminCtrl_lockerContainer">
              <div className="adminCtrl_lockerContainertop">
                <h6>Locker control</h6>
              </div>

              <hr />
              <div className="adminctrl_databoard">
                <div className="adminctrl_databoard_top">
                  <label className="adminctrl_label">
                    Open Selected Locker
                  </label>
                  <select
                    onChange={(e) => {
                      const lock = e.target.value;
                      setLocker(lock);
                      console.log(lock);
                      setDashboardInfo(
                        DashboardInfo.filter((item) =>
                          item.name.toLowerCase().match(locker.toLowerCase())
                        )
                      );
                      console.log(DashboardInfo[0]);
                    }}
                  >
                    {(() => {
                      if (DashboardInfo.length !== 0) {
                        return (
                          <>
                            {DashboardInfo.map((values, key) => {
                              return (
                                <option
                                  className="adminctrl_option"
                                  key={key}
                                  value={values.name}
                                >
                                  {values.name}
                                </option>
                              );
                            })}
                          </>
                        );
                      }
                    })()}
                  </select>
                  <button
                    className="adminctrl_Button"
                    type="submit"
                    onClick={unlock}
                  >
                    Open
                  </button>
                </div>
                <br />
                <div className="adminctrl_databoard_bottom">
                  <label className="adminctrl_label">Open All Locker</label>
                  <button
                    type="button"
                    className="adminctrl_Button"
                    onClick={unlockAll}
                  >
                    Open
                  </button>
                </div>
              </div>
            </div> */}
            <div className="userprofile_lockerContainer">
              <div className="userprofile_lockerContainertop">
                <h3 className="userprofile_lockerContainer_heading">
                  Admin list
                </h3>
              </div>
              <hr />
              <table
                class="reports_lockerContainer_heading2 table"
                style={{ color: "white", height: "200px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">S. No</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Dashboarddata.length !== 0
                    ? Dashboarddata.map((data, index) => (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>{data.fname}</td>
                          <td>{data.lname}</td>
                          <td>{data.email}</td>
                          <td>{data.role}</td>
                          <td style={{display:'flex'}}>
                            <button
                              name={data._id}
                              onClick={editbtn}
                              className="userprofile_input_Button m-2"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              Edit
                            </button>
                            <button
                              className="userprofile_input_Button m-2"
                              name={data.email}
                              onClick={deletebtn}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div
                      class="modal-content"
                      style={{ backgroundColor: "#212529" }}
                    >
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Edit User
                        </h5>
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="userprofile_Form">
                          {/* <label>User Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={name}
                            id="nameedit"
                            className="userprofile_Input"
                          ></input>
                          <br /> */}
                          <label>First Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={firstname}
                            id="fnameedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          <label>Last Name</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={lastname}
                            id="lnameedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          {/* <label>Address</label>
                          <br />
                          <input
                            type="text"
                            defaultValue={address}
                            id="addressedit"
                            className="userprofile_Input"
                          ></input>
                          <br /> */}
                          <label>Email</label>
                          <br />
                          <input
                            type="type"
                            defaultValue={email}
                            id="emailedit"
                            className="userprofile_Input"
                          ></input>
                          <br />
                          <label>Password</label>
                          <br />
                          <input
                            type="type"
                            value={password}
                            // defaultValue={password}
                            id="passwordedit"
                            className="userprofile_Input"
                            onChange={(e)=>setpassword(e.target.value)}
                          ></input>
                          <br />
                          <label>Role</label>
                          <br />
                          <select id="role">employee
                            <option value="employee" >Employee</option>
                            <option value="admin" selected={role==='admin'?true:false}>Admin</option>
                            <option value="superadmin" selected={role==='superadmin'?true:false}>Super Admin</option>
                          </select>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="userprofile_input_Button"
                          onClick={saveuser}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </table>
            </div>
          </div>
          <div className="userprofile_lockerInfo">
            <div className="userprofile_lockerInfotop">
              <h3>Add Admin User</h3>
              <img
                className="userprofile_profileImg"
                src="./assets/profile.png"
                alt=""
              />
            </div>
            <div className="userprofile_Form">
              <label>User Role</label>
              <br />
              <select id="role1">
                <option value="admin" selected >Admin</option>
                <option value="superadmin">Super Admin</option>
              </select>
              <br />
              <label>First Name</label>
              <br />
              <input
                type="text"
                id="fname"
                className="userprofile_Input"
              ></input>
              <br />
              <label>Last Name</label>
              <br />
              <input
                type="text"
                id="lname"
                className="userprofile_Input"
              ></input>
              <br />
              <label>Email</label>
              <br />
              <input
                type="type"
                id="email"
                className="userprofile_Input"
              ></input>
              <br />
              {/* <label>Address</label>
              <br />
              <input
                type="text"
                id="address"
                className="userprofile_Input"
              ></input>
              <br /> */}
              <label>Password</label>
              <br />
              {passwordtype === false ? (
                <input
                  type="password"
                  id="password"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              ) : (
                <input
                  type="text"
                  id="password"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              )}

              {passwordtype !== false ? (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-filled" />
                </span>
              ) : (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-invisible-filled" />
                </span>
              )}
              <br />
              <label>Confirm Password</label>
              <br />
              {passwordtype === false ? (
                <input
                  type="password"
                  id="cpassword"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              ) : (
                <input
                  type="text"
                  id="cpassword"
                  className="userprofile_Input"
                  style={{ width: "80%" }}
                ></input>
              )}

              {passwordtype !== false ? (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-filled" />
                </span>
              ) : (
                <span
                  onClick={changepassword}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginLeft: "270px",
                    marginTop: "-25px",
                  }}
                >
                  <Icon icon="ant-design:eye-invisible-filled" />
                </span>
              )}
              <br />
              <button className="userprofile_input_Button" onClick={adduser}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userprofile;
