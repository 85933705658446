import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Sidebar from "../../components/sidebar";
import "./reports.css";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";

const Reports = () => {
  const [Dashboarddata, setDashboarddata] = useState("");
  const [DashboardInfo, setDashboardInfo] = useState(Dashboarddata);
  const [loginfo, setLogInfo] = useState([]);
  const [reportdata, setReportdata] = useState([]);
  const [reportpercentage, setReportpercentage] = useState([]);
  const [fromtime, setFromtime] = useState(null);
  const [totime, setTotime] = useState(null);
  const [check, setcheck] = useState([]);
  const [userlist, setuserlist] = useState([]);
  const [user1, setuser1] = useState([]);
  useEffect(() => {
    getInfo();
    percentage();
    // setTimeout(() => {
    //   getInfo();
    // }, 2000);
  }, []);
  const getInfo = async () => {
    //https://api.smartdigilocker.com/locker/getlogs

    // const logdata = await axios
    //   .get("https://api.smartdigilocker.com/locker/logdata")
    //   .then((res) => {
    //     return res.data;
    //   });
    const logdata = await axios
      .get("https://api.smartdigilocker.com/locker/getlogs")
      .then((res) => {
        return res.data.logs;
      });
    setLogInfo(logdata);
  };

  const percentage = async () => {
    const { data } = await axios.get(
      "https://api.smartdigilocker.com/locker/lockerdata"
    );
    const totaldata = await axios
      .get("https://api.smartdigilocker.com/locker/occupied")
      .then((res) => {
        return res.data;
      });
    var occupieddaata = await totaldata.filter((data) => {
      console.log(data.user !== '');
      return data.userstatus ===  "Occupied" || data.user !== '';
    });
    var perc = "";
    if (isNaN(data.length) || isNaN(occupieddaata.length)) {
      perc = " ";
    } else {
      perc = ((occupieddaata.length / data.length) * 100).toFixed(3);
    }
    console.log(perc);
    setReportpercentage(Math.round(perc));
    // const {logdata} = await axios.get('https://api.smartdigilocker.com/locker/logdata')
    var user1 = await axios
      .get(`https://api.smartdigilocker.com/user/viewall`)
      .then((res) => {
        return res.data;
      });
    setDashboarddata(data);
    setuserlist(user1);

    //
  };
  // useEffect(() => {
  //     setDashboardInfo(Dashboarddata);
  //     if (Dashboarddata.length !== 0) {
  //         setDashboardInfo(() =>
  //             Dashboarddata.filter((item) =>
  //                 item.status.match("locked")));
  //     }
  // });

  useEffect(() => {
    console.log(fromtime,totime);
    if((fromtime !== null && fromtime !== '') && (totime!== null &&  totime !== '')){
    filterData()
    }
  }, [fromtime,totime])
  
  useEffect(() => {
    var user1 
   const getdata = async ()=>{
    user1 = await axios
    .get(`https://api.smartdigilocker.com/user/viewall`)
    .then((res) => {
      return res.data;
    });
    }
    getdata()
    setuser1(user1)
   
  }, [])
  
  const filterData = async ()=>{

    const empid = document.getElementById("empid").value;
    const lockername = document.getElementById("lockername").value;
    const from = document.getElementById("fromDate").value;
    const to = document.getElementById("toDate").value;
    var obj =  {
      "start_time":from,
      "end_time":to,
      "locker_no":lockername,
      "email":empid
  }
  console.log(obj);

    const filteredData = await axios
    .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
    .then((res) => {
      return res.data.logs;
    });
    console.log(filteredData);
    setLogInfo(filteredData || [])
    }

    const handlechangefromtime = (e) => {
      setFromtime(e.target.value);
      // if (fromtime && totime) {
      //   filterData()
      // }
    }
    const handlechangetotime = async (e) => {
      setTotime(e.target.value);
      // if (fromtime && totime) {
      //   filterData()
      // }
    }

  const clearallbtn = () => {
    // setReportdata([]);
    setFromtime("")
    setTotime("")
    getInfo()
    document.getElementById("fromDate").value = ""
    document.getElementById("toDate").value = ""
    document.getElementById("empid").value = "";
    document.getElementById("lockername").value = "";
  };


  const downloaddata = async () => {
    var arraydata = [];
    for (var i = 0; i < loginfo.length; i++) {
      if (loginfo.length !== 0) {
        arraydata.push({
          S_no: i + 1,
          "User Name": loginfo[i].emailandphone,
          "Role": loginfo[i].role,
          "Locker No": loginfo[i].locker_no,
          "Log Type": loginfo[i].log_type,
          "Checkin Date": loginfo[i].created_Date,
          "Checkin Time": loginfo[i].created_Time,
          "Checkout Date": loginfo[i].checkoutdate,
          "Checkout Time": loginfo[i].checkouttime,

        });
      } else {
        arraydata.push({
          "S_no": i + 1,
          "User_Name": loginfo[i].emailandphone,
          "Role": loginfo[i].role,
          "Locker No": loginfo[i].locker_no,
          "Log Type": loginfo[i].log_type,
          "Checkin Date": loginfo[i].created_Date,
          "Checkin Time": loginfo[i].created_Time,
          "Checkout Date": loginfo[i].checkoutdate,
          "Checkout Time": loginfo[i].checkouttime,

        });
      }
    }

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(arraydata);
  XLSX.utils.book_append_sheet(wb, ws, "report");
  XLSX.writeFile(wb, "report.xlsx");
  };



const Week_download =async ()=>{
    const startDate = moment( new Date()).format('YYYY-MM-DD');
    const endDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD');

    // const empid = document.getElementById("empid").value;
    // const lockername = document.getElementById("lockername").value;
    var obj =  {
      "start_time":endDate,
      "end_time":startDate,
      "locker_no":"",
      "email":""
  }
  console.log(obj);

    const filteredData = await axios
    .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
    .then((res) => {
      return res.data.logs;
    });
    console.log(filteredData);
    setLogInfo(filteredData || [])


}
const Month_download =async ()=>{
  const startDate = moment( new Date()).format('YYYY-MM-DD');
  const endDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  var obj =  {
    "start_time":endDate,
    "end_time":startDate,
    "locker_no":"",
    "email":""
}
console.log(obj);

  const filteredData = await axios
  .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
  .then((res) => {
    return res.data.logs;
  });
  console.log(filteredData);
  setLogInfo(filteredData || [])
//   console.log(startDate);
//   console.log(endDate);
//   var resultProductData = loginfo.filter(function(date) { return date.created_Date <= startDate && date.created_Date >= endDate });
// console.log(resultProductData);
// var arraydata = [];
// for (var i = 0; i < resultProductData.length; i++) {
//   if (resultProductData.length !== 0) {
//     arraydata.push({
//       S_no: i + 1,
//       "User Name": resultProductData[i].emailandphone,
//       "Role": resultProductData[i].role,
//       "Locker No": resultProductData[i].locker_no,
//       "Log Type": resultProductData[i].log_type,
//       "Created Date": resultProductData[i].created_Date,
//       "Created Time": resultProductData[i].created_Time,

//     });
//   } else {
//     arraydata.push({
//       "S_no": i + 1,
//       "User_Name": resultProductData[i].emailandphone,
//       "Role": resultProductData[i].role,
//       "Locker No": resultProductData[i].locker_no,
//       "Log Type": resultProductData[i].log_type,
//       "Created Date": resultProductData[i].created_Date,
//       "Created Time": resultProductData[i].created_Time,
//     });
//   }
// }

// var wb = XLSX.utils.book_new();
// var ws = XLSX.utils.json_to_sheet(arraydata);
// XLSX.utils.book_append_sheet(wb, ws, "report");
// XLSX.writeFile(wb, "report.xlsx");
}

const custom_filter = async()=>{
 const filterType = document.getElementById("customfilter").value
 if(filterType === "1 Week"){
  const startDate = moment( new Date()).format('YYYY-MM-DD');
  const endDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
  var obj =  {
    "start_time":endDate,
    "end_time":startDate,
    "locker_no":"",
    "email":""
}
console.log(obj);

  const filteredData = await axios
  .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
  .then((res) => {
    return res.data.logs;
  });
  console.log(filteredData);
  setLogInfo(filteredData || [])
 }
 else if(filterType === "1 Month"){
  const startDate = moment( new Date()).format('YYYY-MM-DD');
  const endDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
  var obj =  {
    "start_time":endDate,
    "end_time":startDate,
    "locker_no":"",
    "email":""
}
console.log(obj);

  const filteredData = await axios
  .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
  .then((res) => {
    return res.data.logs;
  });
  console.log(filteredData);
  setLogInfo(filteredData || [])
 }
 else if(filterType === "2 Month"){
  const startDate = moment( new Date()).format('YYYY-MM-DD');
  const endDate = moment().subtract(2, 'months').format('YYYY-MM-DD');
  var obj =  {
    "start_time":endDate,
    "end_time":startDate,
    "locker_no":"",
    "email":""
}
console.log(obj);

  const filteredData = await axios
  .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
  .then((res) => {
    return res.data.logs;
  });
  console.log(filteredData);
  setLogInfo(filteredData || [])
 }
 else if(filterType === "3 Month"){
  const startDate = moment( new Date()).format('YYYY-MM-DD');
  const endDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  var obj =  {
    "start_time":endDate,
    "end_time":startDate,
    "locker_no":"",
    "email":""
}
console.log(obj);

  const filteredData = await axios
  .post(`https://api.smartdigilocker.com/locker/filterData`,obj)
  .then((res) => {
    return res.data.logs;
  });
  console.log(filteredData);
  setLogInfo(filteredData || [])
 }
 else{
  getInfo()
 }



}



  return (
    <div className="reports">
      <Sidebar className="reports_Sidebar" />
      <div className="reports_Rightbar">
        <div className="reports_RightbarTop">
          <Icon icon="iconoir:reports" className="reports_Icon" />
          <h1 className="reports_Heading">Reports</h1>
          {/* <form>
            <div className="reports_search_Input">
              <input
                className="reports_search_Inputfields"
                placeholder="Search"
                name="username"
                type="text"
              ></input>
              <label htmlFor="username" className="reports_search_Icon">
                <Icon icon="charm:search" />
              </label>
            </div>
          </form> */}
        </div>
        <div className="reports_RightbarBottom">
          <div className="reports_RightbarBottomleft">

            <div className="tessssssss">
            <div className="reports_lockerContainer">
              <h3>Report Generator</h3>
              <hr />
              <div className="reports_form">
                <label htmlFor="" className="reports_Input_label">
                  From -
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="reports_Input"
                  name="from"
                  onChange={(e) => handlechangefromtime(e)}
                  value={fromtime}
                />
                <label htmlFor="" className="reports_Input_label">
                  To -
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="reports_Input"
                  name="to"
                  onChange={(e) => handlechangetotime(e)}
                   value={totime}
                />
                <label htmlFor="" className="reports_Input_label">
                  Emp Email -
                </label>
                <select onChange={filterData}
                  className="reports_Input_label"
                  id="empid"
                  style={{ width: "180px", marginLeft: "10px" }}
                >
                  <option value="">Select Email</option>
                  {userlist.length !== 0
                    ? userlist.map((data, index) => (
                        <option key={index} value={data.email}>
                          {data.email}
                        </option>
                      ))
                    : null}
                </select>
                <label htmlFor="" className="reports_Input_label">
                  Locker No -
                </label>
                <select onChange={filterData}
                  className="reports_Input_label"
                  id="lockername"
                  style={{ width: "180px", marginLeft: "10px" }}
                >
                  <option value="">Select Locker</option>
                  {Dashboarddata.length !== 0
                    ? Dashboarddata.map((data, index) => (
                        <option key={index} value={data.name}>
                          {data.name}
                        </option>
                      ))
                    : null}
                </select>
                {/* <button
                  className="adminCtrl_lockerInfo_Button"
                  onClick={filterData}
                >
                  Search
                </button> */}
                <button
                  className="adminCtrl_lockerInfo_Button"
                  onClick={clearallbtn}
                >
                  Clear All
                </button>
              </div>
              
            </div>

            <div className="reports_lockerInfo">
            <h3>Locker Occupied</h3>
            <div className="reports_occupied_lockers">
              {/* <div
                className="reports_occupied_lockers_circular"
                style={{
                  background: `conic-gradient(#ea3a3d ${
                    reportpercentage * 3.6
                  }deg,  #F9B959 ${reportpercentage * 3.6}deg)`,
                }}
              >
                <div className="reports_occupied_lockers_value">
                  {reportpercentage}%
                </div>
              </div> */}
                <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{"--value":reportpercentage}}>
                <div className="reports_occupied_lockers_value">
                  {reportpercentage}%
                </div>
                </div>

            </div>
          </div>
            </div>



            <div className="reports_lockerContainer2">
              <div className="reports_lockerContainer2top">
                <h3 className="reports_lockerContainer_heading2">
                  Report list
                </h3>

                <select onChange={custom_filter} 
                  className="reports_Input_label"
                  id="customfilter"
                  style={{ width: "180px", marginLeft: "10px" }}
                >
                  <option value="">Select Range</option>
                  <option value="1 Week">1 Week</option>
                  <option value="1 Month">1 Month</option>
                  <option value="2 Month">2 Month</option>
                  <option value="3 Month">3 Month</option>
              
                </select>

                {/* <button
                  className="adminCtrl_lockerInfo_Button"
                  onClick={Week_download}
                >
                 1 Week
                </button>
                <button
                  className="adminCtrl_lockerInfo_Button"
                  onClick={Month_download}
                >
                3  Month
                </button> */}
                <button
                  className="adminCtrl_lockerInfo_Button"
                  onClick={downloaddata}
                >
                  Download
                </button>
              </div>
              <hr />
              <table
                class="reports_lockerContainer_heading2 table"
                style={{ color: "white", height: "200px" }}
              >
                <thead>
                  <tr>
                    <th scope="col">S. No</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">Locker No</th>
                    {/* <th scope="col">Log Type</th> */}
                    <th scope="col">Check-In Date</th>
                    <th scope="col">Check-In Time</th>
                    <th scope="col">Check-Out Date</th>
                    <th scope="col">Check-Out Time</th>
                    {/* <th scope="col">Locker Name</th>
                    <th scope="col">Login In</th>
                    <th scope="col">Logout</th> */}
                  </tr>
                </thead>
                <tbody style={{position:"relative"}}>
                   {/* {JSON.stringify(loginfo) } */}
                  {loginfo.length !== 0
                    ? loginfo.map((data, index) => (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>{data.emailandphone}</td>
                          <td>{data.role}</td>
                          <td>{data.locker_no}</td>
                          {/* <td>{data.log_type}</td> */}
                          <td>{data.created_Date}</td>
                          <td>{data.created_Time}</td>
                          <td>{data.checkoutdate}</td>
                          <td>{data.checkouttime}</td>

                          
                          {/* <td>{data.lockerdata.name}</td>
                          <td>{data.logdata?.time}</td>
                          <td>{data.logdata?.logout}</td> */}
                        </tr>
                      ))
                    : <div className="noData">"No Data Found"</div>}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Reports;
