import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const API_URL = "https://api.smartdigilocker.com/user/login";
const useLoginForm = (validateInfo) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [submit, SetSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateInfo(values));
    SetSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submit) {
      axios
        .post(API_URL, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          if (
            res.data === "Email or Password Not Exist" ||
            res.data === "Not Valid User.."
          ) {
            alert(res.data);
          } else {
            sessionStorage.setItem("useremail", values.email);
            sessionStorage.setItem("auth", JSON.stringify(res.data));
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return { handleChange, values, handleSubmit, errors };
};

export default useLoginForm;
